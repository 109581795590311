<template>
  <!-- 我的 -->
  <div class="myself">
    <div>
      <van-cell @click="myauth" title="我的实名" is-link />
      <!-- <van-cell @click="mybank" title="我的银行卡" is-link /> -->
      <van-cell @click="help" title="帮助中心" is-link />
    </div>
    <div class="customer" @click="gotoTell">
      <img src="./img/customer.png" alt="" />
      <span>联系客服</span>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import APP from '@/utils/App'
import { routerTag } from '../../utils/index'
export default {
  setup () {
    const state = reactive({})
    const myauth=()=>{
      APP.JUMP_TO(`${routerTag}myauth`)
    }
    // const mybank = () => {
    //   APP.JUMP_TO(`${routerTag}mybankcard`)
    // }
    const help = () => {
      APP.JUMP_TO('https://m.jqtianxia.cn/help-center-BLMX/index.html#/')
    }
    // 联系客服
    const gotoTell = () => {
      window.location.href = 'tel://4006672319'
    }
    onMounted(() => {
      APP.SET_TITLE('我的')
    })
    return {
      ...toRefs(state),
      // mybank,
      help,
      gotoTell,
      myauth
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
